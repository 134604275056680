import React, { useEffect, useState } from 'react';
import { MultiSelect } from '@mantine/core';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import MultiSelectCarBrandsItem from './MultiSelectCarBrandsItem';
import MultiSelectCarBrandsValue from './MultiSelectCarBrandsValue';
import { CarBrand } from '../models';

/**
 * multi select component for car brands
 */
const MultiSelectCarBrandsInput = (props) => {

    // globals
    const [items, setItems] = useState([]);
    const setError = useErrorDispatch();

    /**
     * fetches the car brands
     * @returns array of results
     */
    const fetchData = async () => {
        try {
            const items = await DataStore.query(CarBrand, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            const mappedItems = items.map((e) => {
                return { value: e.id, label: e.name };
            })
            setItems(mappedItems);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }

    };

    /**
     * use effect hook to listen to fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // as long as there are no items fetched, return empty JSX
    if (items.length === 0) {
        return null;
    }

    // render
    return (
        <MultiSelect
            transition="slide-down"
            transitionDuration={80}
            transitionTimingFunction="ease"
            searchable
            data={items}
            label="Automarken"
            placeholder="Automarken wählen..."
            {...props}
            type={null}
            itemComponent={MultiSelectCarBrandsItem}
            valueComponent={MultiSelectCarBrandsValue}
        />
    );
}

// Default export of the class
export default MultiSelectCarBrandsInput;