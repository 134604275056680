import React, { useEffect, useRef, useState } from 'react';
import { AppShell, Navbar, Text, Burger, useMantineTheme, ActionIcon, Group, ScrollArea, Divider, ThemeIcon, UnstyledButton, Header as AppShellHeader } from '@mantine/core';
import { useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { Car, Check, List, Logout, Map, MoonStars, Sun, Tag, Users } from 'tabler-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { ROUTE_CARBRANDS, ROUTE_MAP, ROUTE_MAPENTRIES, ROUTE_ROOT, ROUTE_TAGS, ROUTE_USERS, USERGROUP_ADMIN, USERGROUP_USER } from '../helpers/Constants';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState';
import LoadingOverlay from './LoadingOverlay';
import { showNotification } from '@mantine/notifications';
import { USER_SET, useUserDispatch, useUserState } from '../helpers/GlobalUserState';

/**
 * the app wrapper for global states
 * @returns JSX
 */
export default function AppShellWrapper(props) {

    const theme = useMantineTheme();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
    const [colorScheme, setColorScheme] = useLocalStorage({
        key: 'mantine-color-scheme',
        defaultValue: 'light',
        getInitialValueInEffect: false,
    });
    const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const [opened, setOpened] = useState(false);
    const [appLoading, setAppLoading] = useState(true);
    const [dataStoreReady, setDataStoreReady] = useState(false);
    const navigate = useNavigate();
    const setUser = useUserDispatch();
    const user = useUserState();
    const location = useLocation();
    const authListenerRef = useRef(null);
    const datastoreListenerRef = useRef(null);
    const datastoreInitialzedBefore = useRef(false);

    /**
     * gets the user group from the user
     * @param {*} authenticatedUser the authenticated user
     * @returns the user group
     */
    const getUserGroup = (authenticatedUser) => {
        var userGroups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
        if (userGroups && userGroups.length > 0) {
            if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_USER)) {
                return USERGROUP_USER;
            }

            if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_ADMIN)) {
                return USERGROUP_ADMIN;
            }
        }

        // if the user group is still not set, we can't do anything else then throw an error
        throw new Error("user group could not be determinded");
    }

    /**
     * wrapper to get user details
     */
    const fetchUserDetails = async (data) => {
        // get user
        var authenticatedUser = data;
        if (!authenticatedUser) {
            // in that case the user is maybe already logged in and we need to try to fetch the user
            try {
                authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            }
            catch {
                // an exception is thrown if no user is logged in, in that case just return;#
                return;
            }
        }

        // get user details
        try {
            // get user group
            var userGroup = getUserGroup(authenticatedUser);

            // set new user data
            setUser({
                action: USER_SET,
                values: {
                    id: authenticatedUser.attributes.sub,
                    email: authenticatedUser.attributes.email,
                    userGroup: userGroup,
                }
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {

        // listen to auth events
        authListenerRef.current = Hub.listen('auth', async (data) => {
            if (data.payload.event === 'signIn') {
                await fetchUserDetails(data.payload.data);
            }
        });

        // listen to Datastore events
        datastoreListenerRef.current = Hub.listen('datastore', async hubData => {
            const { event } = hubData.payload;
            if (event === 'ready' || (event.toLowerCase().includes("outbox") && datastoreInitialzedBefore.current === true)) {
                setDataStoreReady(true);
                datastoreInitialzedBefore.current = true;
            }
            else {
                setDataStoreReady(false);
            }
        })

        // init datastore
        DataStore.start();

        // fetch user details
        fetchUserDetails().finally(() => {
            setAppLoading(false);
        });

        // unsubscribe from listeners when unmounting
        return () => {
            if (authListenerRef.current) {
                authListenerRef.current();
            }

            if (datastoreListenerRef.current) {
                datastoreListenerRef.current();
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * handling sign out of the user
     */
    const signOut = () => {
        setLoading(LOADING_SHOW);
        Auth.signOut().then(async () => {
            await DataStore.clear();
            navigate(ROUTE_ROOT);
            showNotification({ message: "Erfolgreich abgemeldet.", color: 'green', icon: <Check /> });
        }).finally(() => {
            setLoading(LOADING_RESET);
        });
    }

    /**
     * generates a menu item
     * @param {Icon} icon the icon to show 
     * @param {string} color the color for the menu item
     * @param {string} label text to show for the menu item
     * @param {string} to the route to move to
     * @returns JSX for menu item
     */
    function MainLink({ icon, color, label, to }) {
        var currentPath = location.pathname.startsWith(to);

        return (
            <UnstyledButton
                component={Link}
                to={to}
                sx={(theme) => ({
                    display: 'block',
                    width: '100%',
                    padding: theme.spacing.xs,
                    marginTop: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                    backgroundColor: currentPath ? (theme.colorScheme === 'dark' ? theme.colors.blue[9] : theme.colors.blue[1]) : theme.colors.transparent,

                    '&:hover': {
                        backgroundColor: !currentPath ? (theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]) : null,
                    },
                })}
                onClick={() => { setOpened(false); }}
            >
                <Group>
                    <ThemeIcon color={color} variant="filled">{icon}</ThemeIcon>
                    <Text size="sm">{label}</Text>
                </Group>
            </UnstyledButton>
        );
    }

    /**
     * gets the header logo and text
     */
    function getHeader() {
        return (
            <>
                <ActionIcon variant="default" size={40} mr="md" disabled>
                    <Map color="gray" size={35} />
                </ActionIcon>
                <Text>AkzoNobel Map</Text>
            </>
        );
    }

    // if the app is loading, show loading screen
    if (!user?.id || appLoading || !dataStoreReady) {
        return (
            <LoadingOverlay loading={true} />
        );
    }

    return (
        <AppShell
            styles={(theme) => ({
                main: {
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                },
            })}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            fixed
            navbar={
                <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 300, lg: 300 }}>
                    {mediaQueryLargerSm ?
                        <Navbar.Section>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {getHeader()}
                            </div>
                            <Divider mt={20} mb="xs" />
                        </Navbar.Section>
                        : null
                    }

                    <Navbar.Section grow component={ScrollArea}>
                        <MainLink icon={<Map />} color="blue" key={ROUTE_MAP} label="Karte" to={ROUTE_MAP} />

                        {user.userGroup === USERGROUP_ADMIN ?
                            <>
                                <MainLink icon={<List />} color="green" key={ROUTE_MAPENTRIES} label="Einträge" to={ROUTE_MAPENTRIES} />
                                <MainLink icon={<Tag />} color="cyan" key={ROUTE_TAGS} label="Tags" to={ROUTE_TAGS} />
                                <MainLink icon={<Car />} color="red" key={ROUTE_CARBRANDS} label="Automarken" to={ROUTE_CARBRANDS} />
                                <MainLink icon={<Users />} color="yellow" key={ROUTE_USERS} label="User" to={ROUTE_USERS} />
                            </>
                            : null
                        }
                    </Navbar.Section>
                    <Divider mt="xs" pb="xs" />

                    <Navbar.Section>
                        <UnstyledButton
                            sx={(theme) => ({
                                display: 'block',
                                width: '100%',
                                padding: theme.spacing.xs,
                                borderRadius: theme.radius.sm,
                                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                                '&:hover': {
                                    backgroundColor:
                                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                },
                            })}
                            onClick={async () => {
                                setOpened(false);
                                signOut();
                            }}
                        >
                            <Group>
                                <ThemeIcon color="red" variant="filled"><Logout size={16} /></ThemeIcon>
                                <Text size="sm">Abmelden</Text>
                            </Group>
                        </UnstyledButton>
                        <Text pt="xs" pl="xs" pr="xs" color="dimmed" size="xs">Angemeldet als:</Text>
                        <Text pl="xs" pr="xs" color="dimmed" size="xs">{user.email}</Text>
                    </Navbar.Section>

                    <Navbar.Section>
                        <Divider mt="xs" pb="xs" />
                        <Group position="apart" pl="xs">
                            <Text size="xs" color="dimmed">{colorScheme === 'dark' ? "Zu hellem Design wechseln" : "Zu dunklem Design wechseln"}</Text>
                            <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={25}>
                                {colorScheme === 'dark' ? <Sun color="gray" size={16} /> : <MoonStars color="gray" size={16} />}
                            </ActionIcon>
                        </Group>
                    </Navbar.Section>
                </Navbar>
            }
            header={!mediaQueryLargerSm ?
                <AppShellHeader height={70} p="md">
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        {getHeader()}
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size="sm"
                            color={theme.colors.gray[6]}
                            ml="auto"
                        />
                    </div>
                </AppShellHeader>
                : null
            }
        >
            {props.children}
        </AppShell>
    )
}