import { Group, Text } from "@mantine/core";
import React, { forwardRef } from "react";
import { CurrentLocation } from "tabler-icons-react";

/**
 * wrapper for the select item for the location search
 */
const LocationSearchItem = forwardRef((props, ref) => (
    <div ref={ref} {...props}>
        <Group noWrap>
            <CurrentLocation size={14} />
            <Text size="sm">{props.label}</Text>
        </Group>
    </div>
));

export default LocationSearchItem;