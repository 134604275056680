/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:1546837d-1222-4ebd-b140-a385d78aafbd",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_a02N4tizp",
    "aws_user_pools_web_client_id": "715tfto39dq4nk7j8rdsb3h5uq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://m2xa6quh2rg73h4bjnj3jb6tpy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://a5eg0e9ah1.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "search_indices": {
                "items": [
                    "placeindex529f302a-prod"
                ],
                "default": "placeindex529f302a-prod"
            },
            "maps": {
                "items": {
                    "map64ed8029-prod": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map64ed8029-prod"
            }
        }
    }
};


export default awsmobile;
