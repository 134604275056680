// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { MapEntry, MapEntryTag, CarBrand, Location } = initSchema(schema);

export {
  MapEntry,
  MapEntryTag,
  CarBrand,
  Location
};