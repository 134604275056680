// user groups
export const USERGROUP_USER = "User";
export const USERGROUP_ADMIN = "Admin";
export const USERGROUPS_ALL = "Alle";
export const USERGROUPS = [
    USERGROUP_USER,
    USERGROUP_ADMIN
]
export const USERGROUPS_WITHALL = [
    USERGROUPS_ALL,
    ...USERGROUPS,
]

// standard routes
export const ROUTE_ROOT = "/";
export const ROUTE_MAP = "/map"
export const ROUTE_MAPENTRIES = "/entries";
export const ROUTE_MAPENTRIES_NEW = `${ROUTE_MAPENTRIES}/new`;
export const ROUTE_MAPENTRIES_EDIT = `${ROUTE_MAPENTRIES}/edit`;
export const ROUTE_TAGS = "/tags";
export const ROUTE_TAGS_NEW = `${ROUTE_TAGS}/new`;
export const ROUTE_TAGS_EDIT = `${ROUTE_TAGS}/edit`;
export const ROUTE_CARBRANDS = "/carbrands";
export const ROUTE_CARBRANDS_NEW = `${ROUTE_CARBRANDS}/new`;
export const ROUTE_CARBRANDS_EDIT = `${ROUTE_CARBRANDS}/edit`;
export const ROUTE_USERS = "/users";

// 404 routes
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_CATCH = "*";

// predicates
export const PREDICATE_CONTAINS = "CONTAINS";

// table
export const TABLE_ACTION_WIDTH = 50;

// admin queries API
export const ADMIN_API_NAME = "AdminQueries";
export const ADMIN_API_LISTUSERS_INGROUP = "/listUsersInGroup";
export const ADMIN_API_CREATENEWUSER = "/createNewUser";
export const ADMIN_API_GETUSER = "/getUser";
export const ADMIN_API_ENABLEUSER = "/enableUser";
export const ADMIN_API_DISABLEUSER = "/disableUser";
export const ADMIN_API_CHANGEUSERADMINPERMISSIONS = "/changeUserAdminPermissions";

// misc
export const OPTION_YES = "Ja";
export const OPTION_NO = "Nein";
export const OPTION_ALL = "Alle";
export const OPTIONS_YESNO = [
    OPTION_YES,
    OPTION_NO
];
export const OPTIONS_YESNO_WITHALL = [
    OPTION_ALL,
    ...OPTIONS_YESNO,
];
