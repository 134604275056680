import { CloseButton } from "@mantine/core";
import React, { forwardRef } from "react";
import CarBrandItem from "./CarBrandItem";

/**
 * wrapper for the car brand select value
 */
const MultiSelectCarBrandsValue = forwardRef((props, ref) => (
    <div ref={ref} {...props.others}>
        <CarBrandItem key={props.value} tag={{ id: props.value, name: props.label }}>
            <CloseButton
                onMouseDown={props.onRemove}
                variant="transparent"
                size={22}
                iconSize={14}
                tabIndex={-1}
            />
        </CarBrandItem>
    </div>
));

export default MultiSelectCarBrandsValue;