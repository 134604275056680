import { LocationSearch, MapView } from "@aws-amplify/ui-react";
import { Accordion, Box, Button, Divider, Drawer, Flex, Group, Radio, Stack, Switch, Text, ThemeIcon, UnstyledButton } from "@mantine/core";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { CarBrand, MapEntry, MapEntryTag } from "../models";
import { useEffect, useRef, useState } from "react";
import { Marker, Popup, FullscreenControl, GeolocateControl } from 'react-map-gl';
import TagItem from "../components/TagItem";
import { FileExport, Filter, FilterOff, LocationOff, Paint, Reload, Settings, SettingsOff } from "tabler-icons-react";
import { isMobile } from 'react-device-detect';
import MultiSelectTagsInput from "../components/MultiSelectTagsInput";
import { FILTER_AND, FILTER_OR, getCombinedFilterPredicates } from "../helpers/Predicates";
import { PREDICATE_CONTAINS } from "../helpers/Constants";
import debounce from "lodash.debounce";
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

// WORKAROUND START
// this is an issue with the production build of mapbox-gl, and a temp fix to add a worker
import { workerClass } from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import workerLoader from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import CarBrandItem from "../components/CarBrandItem";
import MultiSelectCarBrandsInput from "../components/MultiSelectCarBrandsInput";
// eslint-disable-next-line
workerClass = workerLoader;
// WORKAROUND END

/**
 * page implementation for map
 * @returns JSX
 */
export default function Map() {

    // initial view state for the map
    const initialViewState = {
        latitude: 47.6965,
        longitude: 13.4457,
        zoom: 7,
    }

    // initial settings
    const initialShowAllPopups = false;
    const initialShowAddresses = true;
    const initialShowDescription = true;
    const initialShowTags = true;
    const initialShowCarBrands = true;
    const initialFilterCombineMode = FILTER_OR;

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [items, setItems] = useState([]);
    const [tags, setTags] = useState([]);
    const [carBrands, setCarBrands] = useState([]);
    const tagsRef = useRef([]);
    const carBrandsRef = useRef([]);
    const [showAllPopups, setShowAllPopups] = useState(initialShowAllPopups);
    const [showAdresses, setShowAddresses] = useState(initialShowAddresses);
    const [showDescription, setShowDescription] = useState(initialShowDescription);
    const [showTags, setShowTags] = useState(initialShowTags);
    const [showCarBrands, setShowCarBrands] = useState(initialShowCarBrands);
    const [filterCombineMode, setFilterCombineMode] = useState(initialFilterCombineMode);
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [filterTypeTags, setFilterTypeTags] = useState(FILTER_OR);
    const [filterTypeCarBrands, setFilterTypeCarBrands] = useState(FILTER_OR);
    const mapRef = useRef(null);
    const [openedOptions, setOpenedOptions] = useState(["filter", "settings", "reset", "export"]);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            // get items
            setLoading(LOADING_SHOW);

            // fetch tags
            tagsRef.current = await await DataStore.query(MapEntryTag, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
            carBrandsRef.current = await DataStore.query(CarBrand, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
            resetTags();
            resetCarBrands();
            setFilterTypeTags(FILTER_OR);
            setFilterTypeCarBrands(FILTER_OR);
            setFilterCombineMode(FILTER_OR);

            // fetch entries
            await fetchEntries(tags, carBrands, FILTER_OR, FILTER_OR, FILTER_OR);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = useRef(debounce(async (tags, carBrands, filterTypeTags, filterTypeCarBrands, filterCombineMode) => {
        fetchEntries(tags, carBrands, filterTypeTags, filterTypeCarBrands, filterCombineMode);
    }, 300)).current;

    /**
     * Use effect hook to fetch data when filter or sorting changes
     */
    useEffect(() => {
        debouncedFetchData(tags, carBrands, filterTypeTags, filterTypeCarBrands, filterCombineMode);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tags, carBrands, filterTypeTags, filterTypeCarBrands, filterCombineMode]
    );

    /**
     * wrapper to fetch entries
     */
    const fetchEntries = async (tags, carBrands, filterTypeTags, filterTypeCarBrands, filterCombineMode) => {
        // create filters
        const tagObjects = tags.map((e) => {
            return { key: "tagIds", value: e, type: PREDICATE_CONTAINS };
        });
        const carBrandObjects = carBrands.map((e) => {
            return { key: "carBrandIds", value: e, type: PREDICATE_CONTAINS };
        });

        const filter = getCombinedFilterPredicates(filterCombineMode, [
            { values: tagObjects, type: filterTypeTags },
            { values: carBrandObjects, type: filterTypeCarBrands },
        ]);

        // fetch entries
        const fetchedEntries = await DataStore.query(
            MapEntry,
            filter
        );

        const mappedEntries = fetchedEntries.map((e) => {
            return {
                id: e.id,
                name: e.name,
                description: e.description,
                addressText: e.addressText,
                location: e.location,
                showPopup: initialShowAllPopups,
                tagIds: e.tagIds,
                carBrandIds: e.carBrandIds,
            }
        })
        setItems(mappedEntries);
    }

    /**
     * wrapper to reset tags
     */
    const resetTags = () => {
        setTags(tagsRef.current.map((e) => e.id));
    }

    /**
     * wrapper to reset car brands
     */
    const resetCarBrands = () => {
        setCarBrands(carBrandsRef.current.map((e) => e.id));
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        const newItems = items.map((e) => {
            return {
                ...e,
                showPopup: showAllPopups
            }
        });
        setItems(newItems);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showAllPopups]
    );

    /**
     * function to render settings button
     * @param {string} title title of settings button
     * @param {object} icon icon for settings button
     * @param {func} action the action to trigger when clicked
     * @returns 
     */
    const renderSettingsButton = (title, icon, action) => {
        return (
            <UnstyledButton
                sx={(theme) => ({
                    display: 'block',
                    width: '100%',
                    borderRadius: theme.radius.sm,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                    '&:hover': {
                        backgroundColor:
                            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    },
                })}
                onClick={() => { action() }}
            >
                <Group>
                    <ThemeIcon color="gray" variant="outline">{icon}</ThemeIcon>
                    <Text size="sm">{title}</Text>
                </Group>
            </UnstyledButton>
        )
    }

    /**
     * toggles the popup for the item
     * @param {object} item the item to toggle
     */
    const toggleMarker = (item) => {
        const foundIndex = items.findIndex((e) => {
            return e.id === item.id;
        })

        if (foundIndex !== -1) {
            const newItems = [...items];
            newItems[foundIndex].showPopup = !newItems[foundIndex].showPopup;
            setShowAllPopups(false);
            setItems(newItems);
        }
    }

    /**
     * wrapper to render tags for an item
     * @param {object} e the item to render the tags for 
     * @returns JSX
     */
    const renderTags = (e) => {
        if (!e.tagIds || e.tagIds.length === 0) {
            return null;
        }

        const jsx = [];
        e.tagIds.forEach(tag => {
            const tagItem = tagsRef.current.find((e) => e.id === tag);
            if (tagItem) {
                jsx.push(<TagItem key={tagItem.id} tag={tagItem} />);
            }
        });
        return <Flex wrap={"wrap"}>{jsx}</Flex>;
    }

    /**
     * wrapper to render car brands for an item 
     * @param {object} e the item to render the car brands for 
     * @returns JSX
     */
    const renderCarBrands = (e) => {
        if (!e.carBrandIds || e.carBrandIds.length === 0) {
            return null;
        }

        const jsx = [];
        e.carBrandIds.forEach(tag => {
            const carBrand = carBrandsRef.current.find((e) => e.id === tag);
            if (carBrand) {
                jsx.push(<CarBrandItem key={carBrand.id} tag={carBrand} />);
            }
        });
        return <Flex wrap={"wrap"}>{jsx}</Flex>;
    }

    /**
     * renders and entry on the map
     * @param {object} e the item to render
     * @returns JSX
     */
    const renderEntry = (e) => {
        return (
            <div key={e.id}>
                <Marker
                    latitude={e.location.lat}
                    longitude={e.location.lon}
                    onClick={(event) => {
                        event.originalEvent.stopPropagation();
                        toggleMarker(e)
                    }}
                />
                {e.showPopup &&
                    <Popup
                        latitude={e.location.lat}
                        longitude={e.location.lon}
                        offset={{ bottom: [0, -40] }}
                        onClose={() => toggleMarker(e)}
                        focusAfterOpen={false}
                        closeOnClick={false}
                    >
                        <Box
                            sx={(theme) => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                padding: "5px",
                                paddingTop: "12px",
                            })}
                        >
                            <Text>{e.name}</Text>
                            {showAdresses && <Text>{e.addressText}</Text>}
                            {(e.description && showDescription) && <Text color="dimmed">{e.description}</Text>}
                            {showTags && renderTags(e)}
                            {showCarBrands && renderCarBrands(e)}
                        </Box>
                    </Popup>
                }
            </div>
        )
    }

    return (
        <>
            <Box
                sx={() => ({
                    backgroundColor: "transparent",
                    minHeight: "100%",
                    maxHeight: "100%",
                    margin: "-16px",
                })}
            >
                <MapView
                    style={{
                        minHeight: "100%",
                        minWidth: "100%",
                        maxHeight: "100%",
                        maxWidth: "100%",
                    }}
                    initialViewState={initialViewState}
                    attributionControl={false}
                    doubleClickZoom={false}
                    ref={mapRef}
                    language={["de"]}
                    locale="de"
                    preserveDrawingBuffer={true}
                    id="map"
                >
                    <LocationSearch
                        position="top-left"
                        placeholder="Zu Adresse springen"
                        countries={"AUT"}
                        language={"de"}
                        style={{ backgroundColor: "darkGray" }}
                        flyTo={true}
                        marker={false}
                        showResultMarkers={false}
                        zoom={10}
                        id="search"
                    />

                    <FullscreenControl
                        style={{ backgroundColor: "darkGray" }}
                    />

                    <GeolocateControl
                        trackUserLocation={true}
                        style={{ backgroundColor: "darkGray" }}
                        fitBoundsOptions={{
                            maxZoom: 15,
                            zoom: 10
                        }}
                    />

                    {items.map((e) => renderEntry(e))}
                </MapView>
            </Box>

            <Button
                color="gray"
                compact
                leftIcon={<Settings size={16} />}
                sx={() => ({
                    position: "absolute",
                    right: isMobile ? null : 5,
                    left: isMobile ? 10 : null,
                    bottom: isMobile ? null : 5,
                    top: isMobile ? 140 : null,
                })}
                onClick={() => setDrawerOpened(true)}
            >
                Optionen
            </Button>

            <Drawer
                opened={drawerOpened}
                onClose={() => setDrawerOpened(false)}
                title="Kartenoptionen"
                padding="xl"
                size="xl"
                position="right"
                lockScroll={false}
                styles={{
                    root: {
                        padding: "0px !important",
                    },
                    drawer: {
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px !important",
                        paddingTop: "10px !important",
                        flexGrow: 1,
                        overflowY: "auto",
                    },
                    body: {
                        padding: "10px",
                    },
                    title: {
                        paddingLeft: "10px",
                        paddingRight: "10px",
                    },
                    header: {
                        marginBottom: "0px",
                        marginRight: "5px"
                    }
                }}
            >
                <Accordion variant="separated" multiple value={openedOptions} onChange={setOpenedOptions}>
                    <Accordion.Item value="filter">
                        <Accordion.Control icon={<Filter />}>Filtern</Accordion.Control>
                        <Accordion.Panel>
                            <Stack>
                                <Stack spacing={1}>
                                    <Text size="sm">Filtermodus</Text>
                                    <Radio.Group
                                        value={filterCombineMode}
                                        onChange={setFilterCombineMode}
                                    >
                                        <Radio value={FILTER_OR} label="Tags oder Automarken" />
                                        <Radio value={FILTER_AND} label="Tags und Automarken" />
                                    </Radio.Group>
                                </Stack>
                                <Divider />

                                <Stack spacing={1}>
                                    <Text size="sm">Tags</Text>
                                    <Radio.Group
                                        value={filterTypeTags}
                                        onChange={setFilterTypeTags}
                                    >
                                        <Radio value={FILTER_OR} label="Einer der Tags" />
                                        <Radio value={FILTER_AND} label="Alle gewählten Tags" />
                                    </Radio.Group>
                                    <MultiSelectTagsInput
                                        label={null}
                                        value={tags}
                                        onChange={(values) => {
                                            setTags(values);
                                        }}
                                    />
                                    <Button 
                                        variant="outline"  
                                        compact 
                                        mt="xs"
                                        onClick={() => {
                                            resetTags([])
                                        }}
                                    >
                                        Alle Tags auswählen
                                    </Button>
                                    <Button 
                                        variant="outline" 
                                        color="red"
                                        compact 
                                        mt="xs"
                                        onClick={() => {
                                            setTags([])
                                        }}
                                    >
                                        Alle Tags entfernen
                                    </Button>
                                </Stack>
                                <Divider />

                                <Stack spacing={1}>
                                    <Text size="sm">Automarken</Text>
                                    <Radio.Group
                                        value={filterTypeCarBrands}
                                        onChange={setFilterTypeCarBrands}
                                    >
                                        <Radio value={FILTER_OR} label="Eine der Automarken" />
                                        <Radio value={FILTER_AND} label="Alle gewählten Automarken" />
                                    </Radio.Group>
                                    <MultiSelectCarBrandsInput
                                        label={null}
                                        value={carBrands}
                                        onChange={(values) => {
                                            setCarBrands(values);
                                        }}
                                    />
                                    <Button 
                                        variant="outline"  
                                        compact 
                                        mt="xs"
                                        onClick={() => {
                                            resetCarBrands([])
                                        }}
                                    >
                                        Alle Automarken auswählen
                                    </Button>
                                    <Button 
                                        variant="outline"  
                                        color="red"
                                        compact 
                                        mt="xs"
                                        onClick={() => {
                                            setCarBrands([])
                                        }}
                                    >
                                        Alle Automarken entfernen
                                    </Button>
                                </Stack>
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="settings">
                        <Accordion.Control icon={<Settings />}>Einstellungen</Accordion.Control>
                        <Accordion.Panel>
                            <Stack>
                                <Switch
                                    label="Alle Popups anzeigen"
                                    checked={showAllPopups}
                                    onChange={(event) => setShowAllPopups(event.currentTarget.checked)}
                                />
                                <Switch
                                    label="Adressen anzeigen"
                                    checked={showAdresses}
                                    onChange={(event) => setShowAddresses(event.currentTarget.checked)}
                                />
                                <Switch
                                    label="Beschreibungen anzeigen"
                                    checked={showDescription}
                                    onChange={(event) => setShowDescription(event.currentTarget.checked)}
                                />
                                <Switch
                                    label="Tags anzeigen"
                                    checked={showTags}
                                    onChange={(event) => setShowTags(event.currentTarget.checked)}
                                />
                                <Switch
                                    label="Automarken anzeigen"
                                    checked={showCarBrands}
                                    onChange={(event) => setShowCarBrands(event.currentTarget.checked)}
                                />
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="reset">
                        <Accordion.Control icon={<Reload />}>Zurücksetzen</Accordion.Control>
                        <Accordion.Panel>
                            <Stack>
                                {renderSettingsButton(
                                    "Position zurücksetzen",
                                    <LocationOff size={16} />,
                                    () => {
                                        mapRef.current.flyTo({ center: [initialViewState.longitude, initialViewState.latitude], zoom: initialViewState.zoom });
                                    }
                                )}
                                {renderSettingsButton(
                                    "Einstellungen zurücksetzen",
                                    <SettingsOff size={16} />,
                                    () => {
                                        setShowAllPopups(initialShowAllPopups);
                                        setShowAddresses(initialShowAddresses);
                                        setShowDescription(initialShowDescription);
                                        setShowTags(initialShowTags);
                                        setShowCarBrands(initialShowCarBrands);
                                    }
                                )}
                                {renderSettingsButton(
                                    "Filter zurücksetzen",
                                    <FilterOff size={16} />,
                                    () => {
                                        resetTags();
                                        resetCarBrands();
                                        setFilterTypeTags(FILTER_OR);
                                        setFilterTypeCarBrands(FILTER_OR);
                                        setFilterCombineMode(initialFilterCombineMode);
                                    }
                                )}
                                {renderSettingsButton(
                                    "Karte neu laden",
                                    <Reload size={16} />,
                                    () => { preparePage(); }
                                )}
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="export">
                        <Accordion.Control icon={<FileExport />}>Export</Accordion.Control>
                        <Accordion.Panel>
                            <Stack>
                                {renderSettingsButton(
                                    "Export als Bild",
                                    <Paint size={16} />,
                                    () => {
                                        const map = document.getElementById("map");
                                        if (!map) {
                                            setError({ action: ERROR_SHOW, error: new Error("Karte konnte nicht gefunden werden.") });
                                        }

                                        html2canvas(map, {
                                            ignoreElements: function (element) {
                                                if (element.classList.contains('maplibregl-control-container')) {
                                                    return true;
                                                }
                                            }
                                        }).then(canvas => {
                                            const dataUrl = canvas.toDataURL("image/png");
                                            saveAs(dataUrl, 'export.png');
                                        }).catch((err) => {
                                            setError({ action: ERROR_SHOW, error: err });
                                        });


                                    }
                                )}
                            </Stack>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Drawer>
        </>
    )
}