import { Group, Text } from "@mantine/core";
import React, { forwardRef } from "react";
import { Tag } from "tabler-icons-react";

/**
 * wrapper for the tag select item
 */
const MultiSelectTagsItem = forwardRef((props, ref) => (
    <div ref={ref} {...props}>
        <Group noWrap>
            <Tag size={16} color="blue" />
            <Text size="sm">{props.label}</Text>
        </Group>
    </div>
));

export default MultiSelectTagsItem;