import { Amplify, I18n } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

// The I18n translations for the AWS amplify login screen
const authScreenLabels = {
    de: {
        'Sign In': 'Anmelden',
        'Reset your password': 'Passwort zurücksetzen',
        'New Password': 'Neues Passwort',
        'Resend Code': 'Code erneut senden',
        'Submit': 'Absenden',
        'Sign in': 'Anmelden',
        'Password': 'Passwort',
        'Confirm Password': 'Passwort bestätigen',
        'Please confirm your Password': 'Passwort erneut eingeben',
        'Enter your Email': 'E-Mail-Adresse eingeben',
        'Enter your email': 'E-Mail-Adresse eingeben',
        'Enter your Password': 'Passwort eingeben',
        'Forgot your password?': 'Passwort vergessen? ',
        'Reset Password': 'Passwort zurücksetzen',
        'Change Password': 'Passwort ändern',
        'Back to Sign In': 'Zurück zum Login',
        'Username': 'E-Mail-Adresse',
        'Create Account': 'Account anlegen',
        'Your passwords must match': 'Passwörter stimmen nicht überein',
        'Password must have at least 8 characters': 'Passwort muss mindestens 8 Zeichen lang sein',
        'Password must have lower case letters': 'Passwort muss einen Kleinbuchstaben beinhalten',
        'Password must have upper case letters': 'Passwort muss einen Großbuchstaben beinhalten',
        'Password must have special characters': 'Passwort muss ein Sonderzeichen beinhalten',
        'Incorrect username or password.': 'E-Mail-Adresse oder Username ungültig.',
        'Send code': 'Passwort zurücksetzen',
        'Username/client id combination not found.': 'Kein Konto mit E-Mail-Adresse gefunden.',
        'Sending': 'Bearbeiten',
        'Attempt limit exceeded, please try after some time.': 'Anzahl der Versuche überschritten. Bitte warten.',
        'Changing': 'Ändern',
    }
};

// set the translations for the AWS auth component
I18n.setLanguage('de');
I18n.putVocabularies(authScreenLabels);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
