import { PREDICATE_CONTAINS } from "./Constants";

// constants for predicates
export const FILTER_OR = "OR";
export const FILTER_AND = "AND";

/**
 * builds a predicate to filter with
 * @param {array} elements array of elements to build predicates for
 * @returns 
 */
export const getFilterPredicates = (elements) => {
    // filter predicates that have values set
    var filteredElements = elements.filter(e => e.value);

    // check if values are set to build predicates for
    if (filteredElements.length === 0) {
        // no values set, return all predicate
        return null;
    }

    // if values are set, build predicates for the values
    const predicate = p => filteredElements.map(e => {
        return getFilterPredicate(p, e);
    });

    // return array of predicates
    return predicate;
}

/**
 * wrapper to generate combined filter predicates on top level
 * @param {string} type the filter type AND or OR
 * @param {*} elements the elements to filter
 * @returns predicate
 */
export const getCombinedFilterPredicates = (type, elements) => {
    // filter all elements without values
    const filteredElements = elements.filter(e => e.values.length > 0);

    // check if we have elements with values
    if (filteredElements.length === 0) {
        return null;
    }

    // create predicate for each element
    const predicates = p => filteredElements.map(e => {
        if (e.values.length === 0) {
            return p => p;
        }

        switch (e.type) {
            case FILTER_AND:
                return p.and(getFilterPredicates(e.values))
            case FILTER_OR:
                return p.or(getFilterPredicates(e.values))
            default:
                throw new Error("combine predicate element type invalid");
        }
    })

    // create top level predicate and add sub predicates
    switch (type) {
        case FILTER_AND:
            return p => p.and(predicates);
        case FILTER_OR:
            return p => p.or(predicates);
        default:
            throw new Error("combine predicate type invalid");
    }
}

/**
 * builds a predicate line
 * @param {object} p the predicate
 * @param {object} element the element to build one predicate statement for
 * @returns 
 */
const getFilterPredicate = (p, element) => {
    switch (element.type) {
        case PREDICATE_CONTAINS:
            return p[element.key].contains(element.value);
        default:
            throw new Error("predicate type invalid");
    }
}