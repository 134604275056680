import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { ActionIcon, Button, Flex, Group, Modal, Stack, Table, Text } from "@mantine/core";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Edit, Trash, X } from "tabler-icons-react";
import { TABLE_ACTION_WIDTH } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { CarBrand, MapEntryTag } from "../models";
import CarBrandItem from "./CarBrandItem";
import TagItem from "./TagItem";

var moment = require('moment-timezone');
moment.locale('de');

/**
 * list component
 * @param {object} props component props
 * @returns JSX
 */
export default function List(props) {

    // globals
    const subscription = useRef(null);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const [itemToDelete, setItemToDelete] = useState(null);
    const tagsRef = useRef([]);
    const carBrandsRef = useRef([]);
    const initialFetchRef = useRef(false);

    /**
     * Use effect hook to fetch data when filter or sorting changes
     */
    useEffect(() => {
        if (initialFetchRef.current) {
            debouncedFetchData(props.filter, props.sort);
        }

        // unsubscribe from listeners when unmounting
        return () => {
            unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.filter, props.sort]
    );

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData(props.filter, props.sort);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to fetch data
     */
    const fetchData = async (filter, sort) => {

        if (!initialFetchRef.current && props.dataStructure.find((e) => e.key === "tagIds")) {
            try {
                tagsRef.current = await DataStore.query(MapEntryTag, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
            }
            catch (e) {
                setError({ action: ERROR_SHOW, error: e });
                return;
            }
        }

        if (!initialFetchRef.current && props.dataStructure.find((e) => e.key === "carBrandIds")) {
            try {
                carBrandsRef.current = await DataStore.query(CarBrand, Predicates.ALL, { sort: s => s.name(SortDirection.ASCENDING) });
            }
            catch (e) {
                setError({ action: ERROR_SHOW, error: e });
                return;
            }
        }

        // first unsubscribe of already running subscription
        unsubscribe();

        // fetch data
        subscription.current = DataStore.observeQuery(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        ).subscribe({
            next(snapshot) {
                setItems(snapshot.items);
                setLoading(LOADING_RESET);
                initialFetchRef.current = true;
            },
            error(err) {
                setLoading(LOADING_RESET);
                setError({ action: ERROR_SHOW, error: err });
            }
        });
    }

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = useRef(debounce(async (filter, sort) => {
        fetchData(filter, sort);
    }, 300)).current;

    /**
     * wrapper to unsubscribe
     */
    const unsubscribe = () => {
        if (subscription.current) {
            subscription.current.unsubscribe();
            subscription.current = null;
        }
    }

    /**
     * table header JSX
     */
    const tableHeader = <tr>
        {props.editRoute && <th></th>}
        {props.dataStructure.map((e) => (
            <th key={e.key}>{e.title}</th>
        ))}
        {props.deleteCallback && <th></th>}
    </tr>

    /**
     * renders a field in a column
     * @param {object} element data column info
     * @param {*} item item to render
     * @returns JSX
     */
    const renderField = (element, item) => {
        switch (element.type) {
            case "color":
                return <Group>
                    <div style={{ backgroundColor: item[element.key], height: "25px", width: "25px", borderRadius: "25px" }}></div>
                    <Text>{item[element.key]}</Text>
                </Group>;
            case "timestamp":
                return moment(item[element.key]).local().format("DD.MM.YYYY HH:mm");
            case "tags":
                const jsxTags = [];
                item[element.key].forEach(tag => {
                    const tagItem = tagsRef.current.find((e) => e.id === tag);
                    if (tagItem) {
                        jsxTags.push(<TagItem key={tagItem.id} tag={tagItem} />);
                    }
                });
                return <Flex>{jsxTags}</Flex>;
            case "carBrands":
                const jsxCarBrands = [];
                item[element.key].forEach(tag => {
                    const carBrand = carBrandsRef.current.find((e) => e.id === tag);
                    if (carBrand) {
                        jsxCarBrands.push(<CarBrandItem key={carBrand.id} tag={carBrand} />);
                    }
                });
                return <Flex>{jsxCarBrands}</Flex>;
            default:
                return item[element.key];
        }
    }

    /**
     * wrapper to ask user if he really wants to delete the entry and then calls the callback
     * @param {object} item item to delete
     */
    const deleteCallback = (item) => {
        setItemToDelete(item);
    }

    /**
     * wrapper for users confirmation of deletion
     */
    const confirmDelete = async () => {
        await props.deleteCallback(itemToDelete);
        setItemToDelete(null);
    }

    /**
     * table rows JSX
     */
    const rows = items.map((item) => (
        <tr key={item[props.id]}>
            {props.editRoute &&
                <td
                    key={`${item[props.id]}_${"edit"}`}
                    style={{ width: `${TABLE_ACTION_WIDTH}px` }}
                >
                    <ActionIcon
                        color="blue"
                        onClick={() => navigate(`${props.editRoute}/${item[props.id]}`)}
                    >
                        <Edit size={16} />
                    </ActionIcon>
                </td>
            }
            {props.dataStructure.map((element) => (
                <td key={`${item[props.id]}_${element.key}`}>{renderField(element, item)}</td>
            ))}
            {props.deleteCallback &&
                <td
                    key={`${item[props.id]}_${"delete"}`}
                    style={{ width: `${TABLE_ACTION_WIDTH}px` }}
                >
                    <ActionIcon
                        color="red"
                        onClick={() => deleteCallback(item)}
                    >
                        <Trash size={16} />
                    </ActionIcon>
                </td>
            }
        </tr>
    ));

    return (
        <>
            <Table striped highlightOnHover withBorder>
                <thead>{tableHeader}</thead>
                <tbody>{rows}</tbody>
            </Table>
            {itemToDelete &&
                <Modal
                    opened={itemToDelete}
                    onClose={() => setItemToDelete(null)}
                    title="Wirklich löschen?"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    size="xl"
                >
                    <Stack>
                        <Text>{`Möchten Sie "${itemToDelete[props.nameKey]}" wirklich löschen?`}</Text>
                        <Group position="apart">
                            <Button variant="outline" color="yellow" leftIcon={<X />} onClick={() => setItemToDelete(null)}>Abbrechen</Button>
                            <Button variant="outline" color="red" leftIcon={<Trash />} onClick={() => confirmDelete()}>Löschen</Button>
                        </Group>
                    </Stack>
                </Modal>
            }
        </>
    )
}