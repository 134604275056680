import { Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { useNavigate } from "react-router";
import { Plus, Reload } from "tabler-icons-react";
import { useForm } from "../components/Form";
import List from "../components/List";
import SortDirectionInput from "../components/SortDirectionInput";
import SortFieldInput from "../components/SortFieldInput";
import { PREDICATE_CONTAINS, ROUTE_MAPENTRIES_EDIT, ROUTE_MAPENTRIES_NEW } from "../helpers/Constants";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { getFilterPredicates } from "../helpers/Predicates";
import { MapEntry } from "../models";
import { DataStore } from "@aws-amplify/datastore";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";

/**
 * page implementation for map entries list
 * @returns JSX
 */
export default function MapEntries() {

    // globals
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();

    // form hook
    const form = useForm({
        initialValues: {
            name: "",
            sortField: "name",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
        ]);
    }

    /**
     * delete callback for the list
     * @param {object} item the item to delete
     */
    const deleteCallback = async (item) => {
        try {
            setLoading(LOADING_SHOW);
            await DataStore.delete(MapEntry, item.id);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    return (
        <Stack>
            <Group position="apart">
                <Title>Karteneinträge</Title>
                <Button
                    color="green"
                    leftIcon={<Plus />}
                    onClick={() => navigate(ROUTE_MAPENTRIES_NEW)}
                >
                    Neuen Karteneintrag anlegen
                </Button>
            </Group>
            <Divider />

            <form
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        label="Name"
                        placeholder="Name"
                        {...form.getInputProps('name')}
                    />

                    <SortFieldInput
                        data={[
                            { value: 'name', label: 'Name' },
                            { value: 'createdAt', label: 'Erstellzeit' },
                            { value: 'updatedAt', label: 'Aktualisierungszeit' },
                        ]}
                        {...form.getInputProps('sortField')}
                    />

                    <SortDirectionInput
                        {...form.getInputProps('sortDirection')}
                    />

                    <Group position='apart'>
                        <Button variant="outline" leftIcon={<Reload size={14} />} type="reset" color="red">Zurücksetzen</Button>
                    </Group>
                </Stack>
            </form>
            <Divider />

            <List
                type={MapEntry}
                id="id"
                dataStructure={[
                    { key: "name", title: "Name" },
                    { key: "addressText", title: "Adresse" },
                    { key: "description", title: "Beschreibung" },
                    { key: "tagIds", title: "Tags", type: "tags" },
                    { key: "carBrandIds", title: "Automarken", type: "carBrands" },
                    { key: "createdAt", title: "erstellt am", type: "timestamp" },
                    { key: "updatedAt", title: "zuletzt geändert am", type: "timestamp" },
                ]}
                editRoute={ROUTE_MAPENTRIES_EDIT}
                deleteCallback={deleteCallback}
                nameKey="name"
                filter={getFilter()}
                sort={s => s[form.values.sortField](form.values.sortDirection)}
            />
        </Stack>
    )
}