import { Authenticator } from "@aws-amplify/ui-react";
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import AppShellWrapper from './AppShellWrapper';
import GlobalErrorProvider from '../helpers/GlobalErrorState';
import GlobalLoadingProvider from '../helpers/GlobalLoadingState';
import { Center, ColorSchemeProvider, MantineProvider, Title } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import ErrorOverlay from './ErrorOverlay';
import GlobalUserProvider from '../helpers/GlobalUserState';
import LoadingOverlay from './LoadingOverlay';
import { useLocalStorage } from '@mantine/hooks';
import { ROUTE_CARBRANDS, ROUTE_CARBRANDS_EDIT, ROUTE_CARBRANDS_NEW, ROUTE_CATCH, ROUTE_MAP, ROUTE_MAPENTRIES, ROUTE_MAPENTRIES_EDIT, ROUTE_MAPENTRIES_NEW, ROUTE_NOT_FOUND, ROUTE_ROOT, ROUTE_TAGS, ROUTE_TAGS_EDIT, ROUTE_TAGS_NEW, ROUTE_USERS } from '../helpers/Constants';
import PageMap from '../pages/Map';
import PageNotFound from '../pages/NotFound';
import PageMapEntries from "../pages/MapEntries";
import PageTags from "../pages/Tags";
import PageTag from "../pages/Tag";
import PageCarBrands from "../pages/CarBrands";
import PageCarBrand from "../pages/CarBrand";
import PageMapEntry from "../pages/MapEntry";
import * as Sentry from "@sentry/react";
import PageUsers from '../pages/Users';

// sentry integration
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://e94fd6f99248409a8c96a43ef950974e@o672392.ingest.sentry.io/4504423598522368",
        normalizeDepth: 20,
    });
}

/**
 * main app implementation
 * @returns JSX
 */
function App() {
    // color scheme getters and setters
    const [colorScheme, setColorScheme] = useLocalStorage({
        key: 'mantine-color-scheme',
        defaultValue: 'light',
        getInitialValueInEffect: false,
    });
    const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    const amplifyUiComponents = {
        Header() {
            return (
                <Center mb="md"><Title>AkzoNobel Map</Title></Center>
            );
        },
    }

    return (
        <GlobalErrorProvider>
            <GlobalLoadingProvider>
                <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                    <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
                        <NotificationsProvider>
                            <Router>
                                <ErrorOverlay />
                                <LoadingOverlay>
                                    <GlobalUserProvider>
                                        <Authenticator hideSignUp={true} components={amplifyUiComponents}>
                                            <AppShellWrapper>
                                                <Routes>
                                                    <Route exact path={ROUTE_ROOT} element={<Navigate to={ROUTE_MAP} />} />
                                                    <Route exact path={ROUTE_MAP} element={<PageMap />} />

                                                    <Route exact path={ROUTE_MAPENTRIES} element={<PageMapEntries />} />
                                                    <Route exact path={ROUTE_MAPENTRIES_NEW} element={<PageMapEntry />} />
                                                    <Route exact path={ROUTE_MAPENTRIES_EDIT + "/:id"} element={<PageMapEntry />} />

                                                    <Route exact path={ROUTE_TAGS} element={<PageTags />} />
                                                    <Route exact path={ROUTE_TAGS_NEW} element={<PageTag />} />
                                                    <Route exact path={ROUTE_TAGS_EDIT + "/:id"} element={<PageTag />} />

                                                    <Route exact path={ROUTE_CARBRANDS} element={<PageCarBrands />} />
                                                    <Route exact path={ROUTE_CARBRANDS_NEW} element={<PageCarBrand />} />
                                                    <Route exact path={ROUTE_CARBRANDS_EDIT + "/:id"} element={<PageCarBrand />} />

                                                    <Route exact path={ROUTE_USERS} element={<PageUsers />} />

                                                    <Route path={ROUTE_NOT_FOUND} element={<PageNotFound />} />
                                                    <Route path={ROUTE_CATCH} element={<PageNotFound />} />
                                                </Routes>
                                            </AppShellWrapper>
                                        </Authenticator>
                                    </GlobalUserProvider>
                                </LoadingOverlay>
                            </Router>
                        </NotificationsProvider>
                    </MantineProvider>
                </ColorSchemeProvider>
            </GlobalLoadingProvider>
        </GlobalErrorProvider>
    );
}

export default App;
