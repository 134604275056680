import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { Geo } from 'aws-amplify';
import debounce from 'lodash.debounce';
import LocationSearchItem from './LocationSearchItem';
import * as Sentry from "@sentry/react";

/**
 * Error overlay implementation
 */
const LocationSearchInput = (props) => {

    // globals
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState(false);

    // field names from props
    var fieldNames = {
        addressText: props.addressTextFieldName ? props.addressTextFieldName : "addressText",
        location: props.locationFieldName ? props.locationFieldName : "location",
    };

    /**
     * fetches location results by text
     * @param {string}   value location text to search locations for
     * @param {function} cb    the callback to call with results
     * @returns array of results
     */
    const fetchData = async (value) => {
        // if no value set, return 
        if (!value) {
            return [];
        }

        try {
            // get locations
            const results = await Geo.searchByText(value, { countries: ["AUT"] });

            // if no locations found, return
            if (results.count === 0) {
                return [];
            }

            // map results to expected format
            const mappedResults = results.map((e, i) => {
                return {
                    key: i,
                    lat: e.geometry.point[1],
                    lon: e.geometry.point[0],
                    label: e.label,
                }
            });

            // set results
            setSearchResults(mappedResults);
            setSearchError(false);
        }
        catch (e) {
            Sentry.captureException(e);
            setSearchError(true);
            setSearchResults([]);
        }

    };

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = React.useRef(debounce(async (value) => {
        fetchData(value);
    }, 500)).current;

    /**
     * wrapper for input value change
     * @param {string} value text to search locations for
     */
    const onSearchChange = (value) => {
        setFormValues(value, null, null);
    }

    /**
     * handler for the select event
     * @param {object} value the selected entry
     */
    const onSelect = (value) => {
        setFormValues(value.label, value.lat, value.lon);
    }

    /**
     * use effect hook to listen to search text changes
     */
    useEffect(() => {
        debouncedFetchData(props.form.values[fieldNames.addressText]);
    },
        [props.form.values, fieldNames.addressText, debouncedFetchData]
    );

    /**
     * checks if there is an error in the validation for the location fields
     * @returns errortext or false
     */
    const getError = () => {
        if (props.form.errors[`${fieldNames.location}.lon`]) {
            return props.form.errors[`${fieldNames.location}.lon`];
        }

        if (props.form.errors[`${fieldNames.location}.lat`]) {
            return props.form.errors[`${fieldNames.location}.lat`];
        }

        if (props.form.errors[fieldNames.addressText]) {
            return props.form.errors[fieldNames.addressText];
        }

        return false;
    }

    /**
     * gets the correct placeholder text depending on state
     * @returns placeholder text
     */
    const getSearchPlaceholder = () => {
        if (searchError) {
            return "Fehler beim Laden der Ergebnisse, bitte versuchen Sie es erneut.";
        }

        if (!props.form.values[fieldNames.addressText]) {
            return "Adresse eingeben zum Suchen...";
        }

        return "Ergebnisse werden geladen...";
    }

    /**
     * wrapper to set values in form respecting form input field names
     * @param {string} addressText address text to set
     * @param {float}  lat         latitude to set
     * @param {float}  lon         longitude to set
     */
    const setFormValues = (addressText, lat, lon) => {
        var values = { };
        values[fieldNames.addressText] = addressText;
        values[fieldNames.location] = { lat: lat, lon: lon };
        props.form.setValues({...values});
    }

    // render
    return (
        <Autocomplete
            transition="slide-down"
            transitionDuration={80}
            transitionTimingFunction="ease"
            withAsterisk={true}
            label={props.label ? props.label : "Adresse"}
            icon={<Search size={14} />}
            onChange={onSearchChange}
            value={props.form.values[fieldNames.addressText]}
            data={searchResults}
            nothingFound={getSearchPlaceholder()}
            placeholder={props.placeholder ? props.placeholder : "Adresse eingeben..."}
            filter={() => { return true; }}
            onItemSubmit={(value) => onSelect(value)}
            error={getError()}
            itemComponent={LocationSearchItem}
        />
    );
}

// Default export of the class
export default LocationSearchInput;