import { Box, Center, Text } from "@mantine/core";
import { Tag } from "tabler-icons-react";

/**
 * item tag visual
 * @param {object} props component props
 * @returns 
 */
export default function TagItem(props) {
    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                cursor: 'default',
                alignItems: 'center',
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]}`,
                borderRadius: 4,
                marginRight: props.marginRight ? props.marginRight : 4,
                marginTop: 4,
                marginBottom: 4,
                paddingRight: 4,
                paddingLeft: 4,
            })}
        >
            <Center mr={1}>
                <Tag size={16} color="green" />
            </Center>
            <Text sx={{ lineHeight: 1, fontSize: 12, margin: 4 }} size="sm" color="dimmed">{props.tag.name}</Text>
            {props.children && props.children}
        </Box>
    )
}