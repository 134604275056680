import * as Yup from 'yup';
import { OPTIONS_YESNO, OPTIONS_YESNO_WITHALL, USERGROUPS, USERGROUPS_WITHALL } from './Constants';

// validation error messages
export const VALIDATION_ERROR_MAPENTRY_NAME = "Vergeben Sie einen Namen für den Karteneintrag.";
export const VALIDATION_ERROR_CARBRAND_NAME = "Vergeben Sie einen Namen für die Automarke.";
export const VALIDATION_ERROR_ADDRESSTEXT = "Bitte wählen Sie eine Adresse aus den Ergebnissen.";
export const VALIDATION_ERROR_TAG_NAME = "Bitte wählen Sie einen Namen für den Tag.";
export const VALIDATION_ERROR_EMAIL = "E-Mail-Adresse ungültig.";
export const VALIDATION_ERROR_USERGROUP = "Wählen Sie eine Berechtigungsgruppe.";
export const VALIDATION_ERROR_OPTIONS_YESNO = "Wählen Sie eine Option.";

// templates for form validation
export const VALIDATION_SCHEMA_MAPENTRY_NAME = Yup.string().required(VALIDATION_ERROR_MAPENTRY_NAME);
export const VALIDATION_SCHEMA_CARBRAND_NAME = Yup.string().required(VALIDATION_ERROR_CARBRAND_NAME);
export const VALIDATION_SCHEMA_LOCATION = Yup.object().shape({ lat: Yup.number().typeError(VALIDATION_ERROR_ADDRESSTEXT).required(VALIDATION_ERROR_ADDRESSTEXT), lon: Yup.number().typeError(VALIDATION_ERROR_ADDRESSTEXT).required(VALIDATION_ERROR_ADDRESSTEXT) });
export const VALIDATION_SCHEMA_ADDRESSTEXT = Yup.string().required(VALIDATION_ERROR_ADDRESSTEXT);
export const VALIDATION_SCHEMA_TAG_NAME = Yup.string().required(VALIDATION_ERROR_TAG_NAME);
export const VALIDATION_SCHEMA_EMAIL = Yup.string().required(VALIDATION_ERROR_EMAIL).email(VALIDATION_ERROR_EMAIL);
export const VALIDATION_SCHEMA_USERGROUP = Yup.mixed().oneOf(USERGROUPS, VALIDATION_ERROR_USERGROUP).required(VALIDATION_ERROR_USERGROUP);
export const VALIDATION_SCHEMA_USERGROUP_WITHALL = Yup.mixed().oneOf(USERGROUPS_WITHALL, VALIDATION_ERROR_USERGROUP).required(VALIDATION_ERROR_USERGROUP);
export const VALIDATION_SCHEMA_OPTIONS_YESNO = Yup.mixed().oneOf(OPTIONS_YESNO, VALIDATION_ERROR_OPTIONS_YESNO).required(VALIDATION_ERROR_OPTIONS_YESNO);
export const VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL = Yup.mixed().oneOf(OPTIONS_YESNO_WITHALL, VALIDATION_ERROR_OPTIONS_YESNO).required(VALIDATION_ERROR_OPTIONS_YESNO);
