import React, { useEffect, useState } from 'react';
import { MultiSelect } from '@mantine/core';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import MultiSelectTagsItem from './MultiSelectTagsItem';
import MultiSelectTagsValue from './MultiSelectTagsValue';
import { MapEntryTag } from '../models';

/**
 * multi select component for tags
 */
const MultiSelectTagsInput = (props) => {

    // globals
    const [items, setItems] = useState([]);
    const setError = useErrorDispatch();

    /**
     * fetches the tags
     * @returns array of results
     */
    const fetchData = async () => {
        try {
            const items = await DataStore.query(MapEntryTag, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            const mappedItems = items.map((e) => {
                return { value: e.id, label: e.name };
            })
            setItems(mappedItems);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }

    };

    /**
     * use effect hook to listen to fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // as long as there are no items fetched, return empty JSX
    if (items.length === 0) {
        return null;
    }

    // render
    return (
        <MultiSelect
            transition="slide-down"
            transitionDuration={80}
            transitionTimingFunction="ease"
            searchable
            data={items}
            label="Tags"
            placeholder="Tags wählen..."
            {...props}
            type={null}
            itemComponent={MultiSelectTagsItem}
            valueComponent={MultiSelectTagsValue}
        />
    );
}

// Default export of the class
export default MultiSelectTagsInput;