import { CloseButton } from "@mantine/core";
import React, { forwardRef } from "react";
import TagItem from "./TagItem";

/**
 * wrapper for the tag select value
 */
const MultiSelectTagsItem = forwardRef((props, ref) => (
    <div ref={ref} {...props.others}>
        <TagItem key={props.value} tag={{ id: props.value, name: props.label }}>
            <CloseButton
                onMouseDown={props.onRemove}
                variant="transparent"
                size={22}
                iconSize={14}
                tabIndex={-1}
            />
        </TagItem>
    </div>
));

export default MultiSelectTagsItem;